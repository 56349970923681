::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
    border-radius: 10px;
    background-color: #fff;
}

::-webkit-scrollbar {
    width: 10px;
    background-color: #fff;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
    background-color: #4a4a4a;
}
