/* ------------------------------------------------------------------------- */


/* GENEREAL STYLE FORM VALIDATION 100x100 */


/* ------------------------------------------------------------------------- */

/* INPUT CSS */

.hundreds-validation__input_error{
	border-color: $hundreds-error-color !important;
}
label.hundreds-validation__input_error,
p.hundreds-validation__input_error,
label.hundreds-validation__input_error a {
	color: $hundreds-error-color !important;
	border-color: $hundreds-error-color !important;
}

/*.hundreds-validation__input_success{
	border-color: $hundreds-success-color !important;
}*/

/* MESSAGES CSS */

.form-messages{
	margin: 20px 0 30px;
} 

.form-messages__success{
	color: $hundreds-success-color;
}

.form-messages__errors{
	color: $hundreds-error-color;
}

.form-messages_hide{
	display: none;
}
.form-messages_show{
	display: block;
}